<template>
	<div
		id="login"
		class="container-sm flex flex-column align-center mx-auto py-60 px-85 background-white rounded shadow-lg"
		@keydown.enter="login"
	>
		<img
			class="mb-50"
			src="@/assets/terminal.svg"
			alt=""
		/>

		<h2 class="mb-20">Gloymt loyniorð</h2>

		<template v-if="!success">
			<input
				type="text"
				v-model="form.email"
				class="w-100 mb-20 border p-20 color-grey rounded shadow"
				placeholder="Teldupostur"
				@blur="$v.form.email.$touch()"
				autofocus
			/>

			<a
				href="#"
				:class="{ disabled: this.$v.form.$invalid }"
				class="btn flex hover:background-blue-600"
				@click.prevent="login"
			>
				Send teldupost

				<div
					v-if="loading"
					class="flex justify-center ml-10"
				>
					<i class="fas fa-spinner fa-spin fa-lg"></i>
				</div>
			</a>
		</template>

		<template v-else>
			<div class="text-center">
				Vit hava sent ein teldupost til <strong>{{ form.email }}</strong
				>. Trýst á leinkjuna í teldupostinum fyri at koma víðari.
			</div>
		</template>
	</div>
</template>

<script>
import axios from 'axios';

const { required, email } = require('vuelidate/lib/validators');

export default {
	name: 'ForgotPassword',

	data() {
		return {
			loading: false,
			success: false,
			form: {
				email: '',
			},
		};
	},

	validations: {
		form: {
			email: {
				required,
				email,
			},
		},
	},

	methods: {
		async login() {
			if (this.loading) {
				return;
			}

			if (this.$v.form.$invalid) {
				return;
			}

			this.loading = true;

			axios
				.post(`/auth/forgot-password/`, {
					email: this.form.email,
				})
				.then((response) => {
					console.log(response.data);

					this.success = true;
				})
				.catch(() => {})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
